<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="9">
        <v-stepper v-model="camData.step" :value="camData.step" alt-labels>
          <v-stepper-header>
            <template v-for="(page, index) in pages">
              <v-stepper-step :complete="camData.step > page.step" :step="page.step" :key="`${index}-step`">
                <span v-if="camData.step===page.step" class="rouge--text">{{page.title}}</span>
              </v-stepper-step>
              <v-divider v-if="index !== pages.length - 1" :key="index"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content v-for="(page, index) in pages" :key="`${index}-content`" :step="page.step"
                               class="ma-0 pa-0 grey lighten-5">
              <v-card color="grey lighten-5" flat>
                <keep-alive>
                  <component :is="page.component" v-bind="curProps" v-dynamic-events="customEvents"></component>
                </keep-alive>

                <div class="shades white pa-6 pb-12">
                  <v-btn v-if="index !== 0" color="rouge" class="ma-0 ml-4" text outlined @click="camData.step--"
                         style="width: 120px">뒤로가기
                  </v-btn>
                  <v-btn v-if="index !== pages.length - 1" class="rouge white-two--text ma-0 ml-4"
                         @click="nextStep(page.step)"
                         :style="'opacity:' + (page.done ? '1.0' : '0.2') + '; width:120px'">
                    다음
                  </v-btn>
                  <v-btn v-else color="primary" class="ma-0 mr-4" @click="registerCam" style="float:right; width:120px">
                    광고등록
                  </v-btn>
                </div>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showStop" content-class="white-two" persistent max-width="500px">
        <v-card class="px-6 pt-2 pb-4">
          <v-card-title class="pb-1">
            <div>
              <v-icon color="default" @click="showStop=false">close</v-icon>
              <h2 class="mt-4 font-weight-bold headline">광고 등록에 어려움이 있으신가요?</h2>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="body-1">
              광고 등록에 관해 궁금한 점이 있으시다면, <span class="rouge--text">오른쪽 하단 채팅버튼</span>을<span class="desktop"/>
              클릭하여 도움을 받으실 수 있습니다.<span class="desktop"/>
              현재 작성중인 광고를 임시 저장하고, 언제든지 현재 페이지로<span class="desktop"/>
              돌아오실 수 있습니다.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn class="rouge white-two--text" text @click.native="showStop=false">뒤로</v-btn>
            <v-btn color="rouge" class="ma-0 ml-4" text outlined
                   @click="showStop=false; save(); $router.push('/campaign/report')">임시저장 후 종료
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import {EventBus} from "@/components/shared/event-bus";
  import userMixin from '@/components/shared/userMixin'
  import campaignMixin from '@/components/shared/campaignMixin'
  import Page1 from '../../components/campaign/regcam/Page1';
  import Page4 from '../../components/campaign/regcam/Page4';
  import Page5 from '../../components/campaign/regcam/Page5';

  export default {
    name: 'Regcam',
    mixins: [userMixin, campaignMixin],
    computed: {
      curProps: function () {
        // if (this.pages[this.camData.step - 1].component.name === '') {
        return {
          camData: this.camData,
          option: this.option,
          channel: this.camData.channel
        }
        // }
      },
      showStop: {
        set(showDialog) {
          this.$store.commit('setShowDialog', showDialog);
        },
        get() {
          return this.$store.getters.showDialog;
        }
      }
    },
    data() {
      return {
        camData: {
          category: {
            firstName: '매장',
            first: '1cbTA11dQev5s531ukil',
            secondName: '',
            thirdName: ''
          },
          step: 1
        },
        option: {category: {}},
        pages: [
          {
            step: 1,
            title: '캠페인 분류',
            // component: 'Page1'
            component: Page1,
            done: false
          },
          {
            step: 2,
            title_base: ' 기본 정보',
            done: true
          },
          {
            step: 3,
            title_base: ' 상세 정보',
            done: true
          },
          {
            step: 4,
            title: '블로그 채널',
            component: Page4,
            done: true
          },
          {
            step: 5,
            title: '사진 업로드',
            component: Page5,
            done: true
          }
        ],
        showComplete: false,
        customEvents: {
          updateEvent: (e) => {
            let page = this.pages[e.step - 1];
            page.done = true;
            this.$set(this.pages, e.step - 1, page);
            if(e.category && e.category.firstName !== this.camData.category.firstName) {
              this.camData = {
                step: 1,
                id: this.camData.id
              };
            }
            // this._.merge(this.camData, e);
            Object.keys(e).forEach((key) => {
              this.camData[key] = e[key];
            });
            // this.camData = this._.omitBy(this.camData, this._.isUndefined(this.camData));
            // this.camData = this._.defaults(e, this.camData)
          },
          updateChannel: (e) => {
            if (e === 'naver' || e === 'youtube') {
              this.camData.channel = e;
              if (e === 'youtube') {
                this.pages[3].title = '유튜브 채널';
                this.camData.youtube = {};
              }
              else {
                this.pages[3].title = '블로그 채널';
                this.camData.naver = {};
              }
            }
          }
        },
        timer: null
      }
    },
    watch: {
      campaignTemp(value) {
        if(value.step && value.step !== 99) {
          let data = JSON.parse(JSON.stringify(value));
          let step = data.step;
          data.step = 1;
          this.camData = data;
          this.changeComponent();
          this.camData.step = step;
        }
      },
      'camData.step': function(value) {
        this.$store.commit('setCampaignRegStep', value);
      }
    },
    directives: {
      DynamicEvents: {
        bind: function (el, binding, vnode) {
          let allEvents = binding.value;
          if(typeof allEvents !== "undefined"){
            let allEventsName = Object.keys(binding.value);
            allEventsName.forEach(function(event) {
              vnode.componentInstance.$on(event, (eventData) => {
                allEvents[event](eventData);
              });
            });
          }
        },
        unbind: function (el, binding, vnode) {
          vnode.componentInstance.$off();
        }
      },
    },
    methods: {
      async nextStep (step) {
        if(step) {
          this.changeComponent();
        }

        if (step === this.pages.length) {
          this.camData.step = 1
        } else if(this.pages[step - 1].done) {
          this.camData.step = step + 1
        }
        await this.save();
      },
      async save() {
        // this.camData.email = this.camData.email || this.user.email;
        this.camData.companyId = this.camData.companyId || this.user.id;
        // if(this.campaignTemp.id) {
        //   await this.axios.put('/campaign?id=' + this.campaignTemp.id, this.camData);
        //   await this.$store.dispatch('getCampaignInfo', {email: this.user.email});
        // }
        // else {
        if (this.camData.channel === 'youtube') {
          delete this.camData.naver;
        }
        else {
          delete this.camData.youtube;
        }
        this.$store.commit('setCampaignTemp', this.camData);
        await this.axios.post('/campaign', this.camData);

        if ((this.camData.category.firstName === '제품' && this.camData.step === 2) || this.camData.step === 5) {
          await this.$store.dispatch('getCampaignInfo', {id: this.camData.companyId});
        }
        if (this.camData.category.firstName === '매장' && this.camData.step === 5) {
          location.reload();
        }
        // }
      },
      changeComponent() {
        for (let i = 1; i < 3; i++) {
          let page = this.pages[i];
          page.component = () => import('../../components/campaign/regcam/' +
          (this.$categories[this.camData.category.firstName].loc) + '/Page' + (i + 1));
          page.title = this.camData.category.firstName + page.title_base;
          this.$set(this.pages, i, page);
        }
        for (let i = 0; i < this.camData.step - 1; i++) {
          let page = this.pages[i];
          page.done = true;
          this.$set(this.pages, i, page);
        }
      },
      async registerCam() {
        this.camData.step = 99;

        if(!this.$store.getters.loading) {
          this.$store.commit('setLoading', true);
          try {
            this.camData.lastContractDate = new Date();
            this.camData.lastContractDateInsta = new Date(this.$moment().subtract(30, 'years').format('YYYY-MM-DD'));
            this.camData.deadline = (this.camData.category.firstName === '매장') ? 7 : 14;
            ['naver', 'insta'].forEach((channel) => {
              if(this.camData[channel]) {
                this.camData[channel].revisitPeriod = ((this.camData.category.firstName === '매장') ? 60 : 60);
                if(this.camData[channel].number > 0) {
                  this.camData[channel].current = 0;
                }
                this.camData[channel].blackPayment = this.camData[channel].blackPayment || 0;
                this.camData[channel].redPayment = this.camData[channel].redPayment || 0;
                this.camData[channel].yellowPayment = this.camData[channel].yellowPayment || 0;
                if (this.camData.category.firstName === '제품') {
                  this.camData[channel].deliveryCharge = 0;
                }
              }
            });

            this.camData.code = "55";

            await this.save();
            this.$store.commit('setCampaignTemp', {step: 99});

            this.$store.commit('setMsg', '저장이 완료되었습니다.');
            this.$store.commit('setShowSnackbar', true);

            this.$store.dispatch('getCampaignInfo', {id: this.user.id});
            this.$router.push('/campaign/search');
          }
          catch (err) {
            console.log(err);
          }
          this.$store.commit('setLoading', false);
        }
      },
    },
    beforeCreate() {
      this.axios.get('/company/option').then(option => {
        this.option = option.data;
      }).catch((err) => {
        console.log(err);
      });
    },
    created() {
      if (this.campaignTemp.step && this.campaignTemp.step !== 99) {
        this.camData = JSON.parse(JSON.stringify(this.campaignTemp));
        this.changeComponent();
      }
      this.pages[3].title = (this.camData.channel === 'youtube') ? '유튜브 채널' : '블로그 채널';
      if (!this.camData.channel) {
        this.camData.channel = 'naver';
        this.$channel.forEach((channel) => {
          if (this.camData[channel]) {
            this.camData.channel = channel;
          }
        });
      }
      else if (!this.camData[this.camData.channel]) {
        this.camData[this.camData.channel] = {};
      }
      EventBus.$on("save:campaign", async () => {
        await this.save();
        await this.$store.dispatch('getCampaignInfo', {id: this.camData.companyId});
      });
      // this.timer = setInterval(async () => {
      //   if (this.$route.path === '/regcam') {
      //     await this.save();
      //   }
      //   else {
      //     clearInterval(this.timer);
      //   }
      // }, 3*60000);
    },
    beforeUnmount() {
      clearInterval(this.timer);
      this.timer = null;
    }
  };
</script>

<style scoped lang="scss">
  @import 'src/assets/scss/custom';

  ::v-deep h3 {
    height: 24px;
    font-size: 18px;
  }

  ::v-deep .v-btn-toggle .v-btn:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  ::v-deep .v-btn-toggle .v-btn:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  ::v-deep .estimate {
    border-radius: 1px;
    background-color: #fcf2f3;
    padding: 10px;
  }

  ::v-deep .light_bulb {
    width: 14px;
    height: 20px;
    object-fit: contain;
    background-color: #00000000;
  }

  ::v-deep .line {
    border-bottom: 1px solid #c2c2c2;
    border-bottom: 1px solid var(--pinkish-grey-color);
    width: 100%;
  }

  ::v-deep .label {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #484848;
  }

  ::v-deep .level_icon {
    width: 56px;
    height: 67px;
    object-fit: contain;
  }

  ::v-deep .text-right input {
    text-align: right;
  }
</style>